import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import SidePanel from '../../../data/components/SidePanel';
import SupportTabs from '../../../data/components/SupportTabs/SupportTabs';
import { Typography, Box } from '@mui/material';
import Button from '../../../data/components/Button';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import Modal from '../../../data/components/Modals/Modal';
import useModal from '../../../utils/hooks/useModal';
import TicketModal from '../../../data/components/Modals/TicketModal/TicketModal';
import {
    createTicket,
    ticketsSelectors,
} from '../../../store/tickets/ticketSlice';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import t from 'utils/Translator';

const columns = [
    {
        field: 'ref',
        headerName: 'Référence',
        renderCell: params => (
            <Typography sx={{ fontSize: 20 }}>{params.value}</Typography>
        ),
        minWidth: 200,
        flex: 1,
    },
    {
        field: 'label',
        headerName: 'Sujet',
        renderCell: params => <Typography>{params.value}</Typography>,
        minWidth: 200,
        flex: 1,
    },
    {
        field: 'description',
        headerName: 'Description',
        renderCell: params => <Typography>{params.value}</Typography>,
        minWidth: 200,
        flex: 1,
    },
    {
        field: 'type',
        headerName: 'Catégorie',
        renderCell: params => (
            <Typography>{t.get(`tickets.type.${params.value}`)}</Typography>
        ),
        minWidth: 200,
        flex: 1,
    },
    {
        field: 'state',
        headerName: 'État',
        renderCell: params => (
            <Typography>{t.get(`tickets.state.${params.value}`)}</Typography>
        ),
        minWidth: 200,
        flex: 1,
    },
    {
        field: 'created_at',
        headerName: 'Date de création',
        renderCell: params => (
            <Typography>{moment(params.value).format('DD/MM/YYYY')}</Typography>
        ),
        width: 100,
        flex: 1,
    },
];

const Support = props => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [ticket, setTicket] = useState(false);
    const [modalIsOpen, toggleModalIsOpen] = useModal();
    const tickets = useSelector(ticketsSelectors.selectAll);

    const rows = tickets;

    return (
        <Box className={classes.container}>
            <Box
                className={
                    props.headerExpanded
                        ? classes.content
                        : classes.contentExpanded
                }
            >
                <Box className={classes.buttonRow}>
                    <Button
                        className={classes.declareTicket}
                        onClick={toggleModalIsOpen}
                    >
                        <Typography sx={{ fontSize: 28 }}>
                            Déclarer un incident
                        </Typography>
                    </Button>
                </Box>

                <Box sx={{ width: '100%', height: 500 }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        onCellClick={params => {
                            toggleModalIsOpen();
                            setTicket(params.row);
                        }}
                    />
                </Box>
            </Box>
            <SidePanel
                sidePanelContainerRef={props.sidePanelContainerRef}
                sidePanelIsOpen={isOpen}
                setSidePanelIsOpen={setIsOpen}
            >
                <SupportTabs />
            </SidePanel>
            <Modal
                open={modalIsOpen}
                closeFunction={() => {
                    toggleModalIsOpen();
                    setTicket(false);
                }}
                modalForm
            >
                <TicketModal
                    ticket={ticket}
                    close={toggleModalIsOpen}
                    createTicket={props.createTicket}
                />
            </Modal>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    content: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column',
        alignSelf: 'center',
        width: '80%',
        height: 700,
    },
    contentExpanded: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column',
        alignSelf: 'center',
        width: '80%',
        height: 900,
    },
    buttonRow: {
        display: 'flex',
        justifyContent: 'center',
    },
    declareTicket: {
        width: 400,
        height: 100,
        boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.20)',
        '&:hover': {
            backgroundColor: '#bebebe',
            opacity: 0.7,
        },
        backgroundColor: '#fff',
    },
}));

const mapDispatch = dispatch =>
    bindActionCreators(
        {
            createTicket,
        },
        dispatch,
    );

export default connect(null, mapDispatch)(Support);
