import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import toArray from 'yup/es/util/toArray';
import Grid from 'data/components/Grid';
import StatsChartsContainer from '../../../data/components/StatsChartsContainer/StatsChartsContainer';
import SidePanel from '../../../data/components/SidePanel';
import StatsFilter from './components/StatsFilter';
import Stats from 'data/stats/api';
import { selectGeneratorsIds } from '../../../store/generators/generatorSlice';
import Loader from '../Login/components/Loader';
import { selectHomeNetworkStats } from '../../../store/stats/statsSlice';

const StatsScreen = props => {
    const [loading, setLoading] = useState(true);
    const generators = useSelector(selectGeneratorsIds);
    const [consoStats, setConsoStats] = useState([]);
    const [prodStats, setProdStats] = useState([]);
    const [autoConsoStats, setAutoConsoStats] = useState([]);
    const [RT2012, setRT2012] = useState([]);

    const [filter, setFilter] = useState('hours');
    const [homeNetworkStats, setHomeNetworkStats] = useState({});
    const [co2, setCo2] = useState(0);
    const [sidePanelIsOpen, setSidePanelIsOpen] = useState(true);

    const storedStats = useSelector(selectHomeNetworkStats);

    const newConsoStats = consoStats.map((stat, i) =>
        Object.assign({
            wh: stat.watth,
            timestamp: stat.timestamp,
            step: stat.step,
            date: stat.date,
        }),
    );

    const consoProdStats = newConsoStats.map((stat, i) =>
        Object.assign({}, stat, prodStats[i], autoConsoStats[i]),
    );

    consoProdStats.sort((a, b) => a.timestamp - b.timestamp);

    const getWebStats = async filter => {
        let by;
        if (filter === 'hours') {
            by = 'hours';
        } else if (filter === 'rollingHours') {
            by = 'rolling_hours';
        } else if (filter === 'rollingDays') {
            by = 'rolling_days';
        } else if (filter === 'rollingMonths') {
            by = 'rolling_months';
        } else if (filter === 'rollingYears') {
            by = 'rolling_years';
        }

        const hasProd = generators.length >= 1;

        let webStats;

        if (by === 'hours') {
            webStats = storedStats;
        } else {
            setLoading(true);
            webStats = await Stats.getStatsWeb(true, by, hasProd);
        }

        if (webStats.energy_detail) {
            setConsoStats(
                toArray(Object.values(webStats?.energy_detail?.conso)),
            );
            setProdStats(toArray(Object.values(webStats?.energy_detail?.prod)));
        }
        if (webStats.autoconso_detail) {
            setAutoConsoStats(
                toArray(Object.values(webStats?.autoconso_detail)),
            );
        }
        if (!webStats.rt2012Detail.global) {
            setRT2012([
                {
                    name: 'Chauffage',
                    value: 0,
                },
                {
                    name: 'ECS',
                    value: 0,
                },
                {
                    name: 'Prises',
                    value: 0,
                },
                {
                    name: 'Autres',
                    value: 0,
                },
            ]);
        } else {
            setRT2012([
                {
                    name: 'Chauffage',
                    value: webStats.rt2012Detail.global.chauffage,
                },
                {
                    name: 'ECS',
                    value: webStats.rt2012Detail.global.ecs,
                },
                {
                    name: 'Prises',
                    value: webStats.rt2012Detail.global.prises,
                },
                {
                    name: 'Autres',
                    value: webStats.rt2012Detail.global.autres,
                },
            ]);
        }
        if (webStats.autoConsoRate) {
            setHomeNetworkStats({
                autoConsoRate: webStats.autoConsoRate,
                autoConsoValue: webStats.autoConsoValue,
                consoFoyer: webStats.consoFoyer,
                exportType: webStats.exportType,
                exported: webStats.exported,
                imported: webStats.imported,
                prodPV: webStats.prodPV,
            });
            const newCo2 = Math.round(webStats.prodPV * 0.18 * 100) / 100;
            setCo2(newCo2);
        }
    };

    useEffect(() => {
        getWebStats(filter).then(stats => setLoading(false));
    }, [filter]);

    return (
        <>
            <StatsChartsContainer
                filteredConsoProdStats={consoProdStats}
                filteredRT2012={RT2012}
                sidePanelContainerRef={props.sidePanelContainerRef}
                sidePanelIsOpen={sidePanelIsOpen}
                co2={co2}
                filter={filter}
                homeNetworkStats={homeNetworkStats}
            />
            {window.innerWidth > 800 && (
                <SidePanel
                    sidePanelContainerRef={props.sidePanelContainerRef}
                    sidePanelIsOpen={sidePanelIsOpen}
                    setSidePanelIsOpen={setSidePanelIsOpen}
                >
                    <StatsFilter
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                    />
                </SidePanel>
            )}
            <Loader loading={loading} />
        </>
    );
};

export default StatsScreen;
