import Menu from 'data/components/Menu';
import t from 'utils/Translator';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { logout } from 'store/auth/authSlice';
import MiddlewareSocket from '../../../../MiddlewareSocket';
import { removeTokenFromStorages } from '../../../../utils';

export default function MobileNavMenu() {
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <Menu
            label="Icon"
            items={[
                {
                    label: t.get('tabs.dashboard'),
                    onClick: () => {
                        window.dispatchEvent(new Event('popstate'));
                        history.push('/dashboard');
                    },
                },
                {
                    label: t.get('tabs.stats'),
                    onClick: () => {
                        window.dispatchEvent(new Event('popstate'));
                        history.push('/stats');
                    },
                },
                {
                    label: t.get('tabs.products'),
                    onClick: () => {
                        window.dispatchEvent(new Event('popstate'));
                        history.push('/products');
                    },
                },
                {
                    label: t.get('tabs.plannings'),
                    onClick: () => {
                        window.dispatchEvent(new Event('popstate'));
                        history.push('/plannings');
                    },
                },
                {
                    label: t.get('tabs.support'),
                    onClick: () => {
                        window.dispatchEvent(new Event('popstate'));
                        history.push('/support');
                    },
                },
                {
                    label: t.get('tabs.account'),
                    onClick: () => {
                        window.dispatchEvent(new Event('popstate'));
                        history.push('/account');
                    },
                },
                {
                    label: t.get('tabs.logout'),
                    onClick: () => {
                        MiddlewareSocket.destroy();
                        removeTokenFromStorages('token');
                        dispatch(logout());
                        history.push('/login');
                    },
                },
            ]}
        />
    );
}
