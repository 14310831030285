import React from 'react';
import WeatherHeader from './Weather/WeatherHeader';
import ConsumptionHeader from './Consumption/ConsumptionHeader';
import ConsumptionContent from './Consumption/ConsumptionContent';
import Accordion from 'data/components/Accordion';
import Grid from 'data/components/Grid';
import WeatherContent from './Weather/WeatherContent';
import { useSelector } from 'react-redux';
import { selectHomeNetworkStats } from 'store/stats/statsSlice';
import { selectGeneratorsIds } from '../../../store/generators/generatorSlice';
import Scheme from '../../../data/components/Scheme/scheme';

export default function Header({ isExpanded, setIsExpanded, ...props }) {
    const generators = useSelector(selectGeneratorsIds);
    const homeNetworkStats = useSelector(selectHomeNetworkStats);

    const renderHeader = () => {
        return (
            <>
                <Grid container spacing={2}>
                    {window.innerWidth > 800 && (
                        <Grid
                            item
                            container
                            xs={12}
                            md={6}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <WeatherHeader isExpanded={isExpanded} />
                        </Grid>
                    )}
                    <Grid
                        item
                        container
                        xs={12}
                        md={6}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <ConsumptionHeader
                            isExpanded={isExpanded}
                            homeNetworkStats={homeNetworkStats}
                        />
                    </Grid>
                </Grid>
            </>
        );
    };

    const renderContent = () => {
        return (
            <Grid container spacing={2}>
                <Grid container item xs={12} md={6} alignItems="center">
                    <WeatherContent isExpanded={isExpanded} />
                </Grid>
                {generators.length >= 1 ? (
                    <Grid item xs={12} md={5}>
                        <ConsumptionContent
                            isExpanded={isExpanded}
                            homeNetworkStats={homeNetworkStats}
                        />
                    </Grid>
                ) : (
                    <div
                        style={{ position: 'absolute', right: 100, top: '40%' }}
                    >
                        <Scheme
                            color={'#fff'}
                            textColor={'#fff'}
                            homeNetworkStats={homeNetworkStats}
                            header
                        />
                    </div>
                )}
            </Grid>
        );
    };

    const handleChange = (e, expanded) => {
        window.innerWidth > 800 && setIsExpanded(expanded);
    };

    return (
        <Accordion
            header={renderHeader()}
            content={renderContent()}
            onChange={handleChange}
            isExpanded={isExpanded}
        />
    );
}
