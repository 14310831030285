import React from 'react';
import t from 'utils/Translator';
import { useLocation } from 'react-router';
import Grid from 'data/components/Grid';
import { makeStyles } from '@mui/styles';
import NavMenu from './components/NavMenu';
import NavBarTabs from 'data/components/NavBarTabs';
import MobileNavMenu from './components/MobileNavMenu';

const PATHNAMES = [
    '/dashboard',
    '/stats',
    '/products',
    '/plannings',
    '/support',
];
const renderTabs = () => {
    return PATHNAMES.map(path => ({
        label: t.get(`tabs.${path.substr(1)}`),
        value: path,
    }));
};

export default function NavBar() {
    const classes = useStyles();
    const { pathname } = useLocation();

    return (
        <>
            <Grid container className={classes.container}>
                {window.innerWidth > 800 ? (
                    <>
                        <Grid item sm={1} xs={6} className={classes.menu} />
                        <Grid item sm={10} xs={12} className={classes.tabs}>
                            <NavBarTabs
                                tabs={renderTabs()}
                                value={PATHNAMES.includes(pathname) && pathname}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                            sm={1}
                            xs={6}
                            className={classes.logout}
                        >
                            <NavMenu />
                        </Grid>
                    </>
                ) : (
                    <MobileNavMenu />
                )}
            </Grid>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.background.default,
        position: 'relative',
    },
    menu: {},
    tabs: {},
    logout: {},
}));
