export const MIDDLEWARE_CONFIGURATION = {
    options: {},
    timeout: 15000,
};

const configs = {
    fheconnect: {
        env: 'fheconnect',
        color: '#00988E',
        API_URL: 'https://apiv2.solidom.net/fheconnect/v1.1/',
        API_RESOURCES_URL: 'https://ressources.solidom.net/fheconnect/v1.0/',
        GOOGLE_CLIENT_ID:
            '400696028447-56hvs8qetqjn8jef7a3s38cvisonc3pq.apps.googleusercontent.com',
        FACEBOOK_CLIENT_ID: '550403632112172',
    },
    mghabitat: {
        env: 'mghabitat',
        color: '#00988E',
        API_URL: 'https://apiv2.solidom.net/mghabitat/v1.1/',
        API_RESOURCES_URL: 'https://ressources.solidom.net/mghabitat/v1.0/',
        GOOGLE_CLIENT_ID:
            '',
        FACEBOOK_CLIENT_ID: '',
    },
};

export const config = configs.fheconnect;
