import React from 'react';
import Button from 'data/components/Button';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {
    linkProvider,
    loginWithProvider,
    unlinkProvider,
} from 'store/auth/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { config } from 'config';
import LoginIcon from './LoginIcon';
import { selectCurrentUser } from 'store/auth/authSlice';

export default function LoginFacebook({ rememberMe, toDashboard, link }) {
    const { email_facebook } = useSelector(selectCurrentUser);
    const dispatch = useDispatch();

    const handleOnClick = async onClick => {
        if (link && email_facebook) {
            await dispatch(unlinkProvider('facebook'));
        } else {
            onClick();
        }
    };

    const handleResponse = async ({ accessToken }) => {
        if (link) {
            await dispatch(
                linkProvider({ code: accessToken, provider: 'facebook' }),
            );
        } else {
            const { payload } = await dispatch(
                loginWithProvider({
                    code: accessToken,
                    provider: 'facebook',
                    rememberMe,
                }),
            );
            return toDashboard(payload);
        }
    };

    return (
        <FacebookLogin
            appId={config.FACEBOOK_CLIENT_ID}
            autoLoad={false}
            scope="public_profile,email"
            fields="name,email,picture"
            callback={handleResponse}
            render={renderProps => (
                <Button
                    onClick={() => handleOnClick(renderProps.onClick)}
                    color="dark"
                    variant="outlined"
                    fullWidth
                    startIcon={<LoginIcon provider="facebook" />}
                >
                    {link ? email_facebook || 'Lier' : 'Facebook'}
                </Button>
            )}
        />
    );
}
