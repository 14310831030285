import React from 'react';
import t from 'utils/Translator';
import Paper from "@mui/material/Paper";
import {makeStyles} from "@mui/styles";
import Button from "../Button";

const MessageCard = props => {
    const classes = useStyles();

    return (
        <Paper className={classes.container}>
            <h2 id="modal-title">{props.message.titre}</h2>
            <p>{props.message ? props.message.texte : 'Aucun Message'}</p>

            <Button variant="contained" onClick={props.hide}>
                <p>{t.get('rgpd-modal.body.button.close')}</p>
            </Button>
        </Paper>
    );
};

const useStyles = makeStyles((theme, modalForm) => ({
    container: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 350,
        position: 'absolute',
        zIndex: 99999,
        backgroundColor: theme.palette.background.default,
        // border: '2px solid #333',
        // borderRadius: 30,
        padding: theme.spacing(2),
        overflowY: 'auto',
    },
}));

export default MessageCard;
