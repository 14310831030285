import React from 'react';
import { makeStyles } from '@mui/styles';
import t from 'utils/Translator';
import Text from '../Text';
// Import all the icons
import LogoFHE from '../../../assets/svgs/LogoFHE';
import CollectedInfosIcon from '../../../assets/svgs/CollectedInfosIcon';
import FinaliteIcon from '../../../assets/svgs/FinaliteIcon';
import ConservationIcon from '../../../assets/svgs/ConservationIcon';
import TransmissionIcon from '../../../assets/svgs/TransmissionIcon';
import AccessIcon from '../../../assets/svgs/AccessIcon';
import ModificationIcon from '../../../assets/svgs/ModificationIcon';
import OppositionIcon from '../../../assets/svgs/OppositionIcon';
import MailIcon from '../../../assets/svgs/MailIcon';
import AddressIcon from '../../../assets/svgs/AddressIcon';
import InfosIcon from '../../../assets/svgs/InfosIcon';

const ModalRGPD = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <LogoFHE color="#fff" width={40} height={40} />
                <h1>{t.get('rgpd-modal.title')}</h1>
            </div>
            <div className={classes.infoBlock}>
                <div className={classes.items}>
                    <CollectedInfosIcon color="#fff" width={25} height={25} />
                    <div className={classes.textGroup}>
                        <Text bold>
                            {t.get('rgpd-modal.body.paragraph1.1')}{' '}
                        </Text>
                        <Text>{t.get('rgpd-modal.body.paragraph1.2')}</Text>
                    </div>
                </div>
                <div className={classes.items}>
                    <FinaliteIcon color="#fff" width={30} height={30} />
                    <div className={classes.textGroup}>
                        <Text bold>
                            {t.get('rgpd-modal.body.paragraph2.1')}{' '}
                        </Text>
                        <Text>{t.get('rgpd-modal.body.paragraph2.2')}</Text>
                    </div>
                </div>
                <div className={classes.items}>
                    <ConservationIcon color="#fff" width={30} height={30} />
                    <div className={classes.textGroup}>
                        <Text bold>
                            {t.get('rgpd-modal.body.paragraph3.1')}{' '}
                        </Text>
                        <Text>{t.get('rgpd-modal.body.paragraph3.2')}</Text>
                        <Text>{t.get('rgpd-modal.body.paragraph3.3')}</Text>
                    </div>
                </div>
                <div className={classes.items}>
                    <TransmissionIcon color="#fff" width={40} height={40} />
                    <div className={classes.textGroup}>
                        <Text bold>
                            {t.get('rgpd-modal.body.paragraph4.1')}{' '}
                        </Text>
                        <Text>{t.get('rgpd-modal.body.paragraph4.2')}</Text>
                    </div>
                </div>
            </div>
            <div className={classes.accessSection}>
                <Text bold>
                    {t.get('rgpd-modal.body.title1').toUpperCase()}
                </Text>
                <div className={classes.accessItems}>
                    <div className={classes.infoItem}>
                        <AccessIcon color="black" width={30} height={30} />
                        <Text style={{ display: 'inline' }}>
                            {t.get('rgpd-modal.body.paragraph5')}
                        </Text>
                    </div>
                    <div className={classes.infoItem}>
                        <ModificationIcon
                            color="black"
                            width={30}
                            height={30}
                        />
                        <Text style={{ display: 'inline' }}>
                            {t.get('rgpd-modal.body.paragraph6.1')}
                        </Text>
                        <Text style={{ display: 'inline' }}>
                            {t.get('rgpd-modal.body.paragraph6.2')}
                        </Text>
                    </div>
                    <div className={classes.infoItem}>
                        <OppositionIcon color="black" width={30} height={30} />
                        <Text style={{ display: 'inline' }}>
                            {t.get('rgpd-modal.body.paragraph7.1')}
                        </Text>
                        <Text style={{ display: 'inline' }}>
                            {t.get('rgpd-modal.body.paragraph7.2')}
                        </Text>
                    </div>
                </div>
            </div>
            <div className={classes.contactSection}>
                <Text bold>
                    {t.get('rgpd-modal.body.title2').toUpperCase()}
                </Text>
                <div className={classes.contactItems}>
                    <div className={classes.items}>
                        <MailIcon color="black" width={30} height={30} />
                        <Text style={{ marginLeft: 8, textAlign: 'center' }}>
                            {t.get('rgpd-modal.body.paragraph11')}
                        </Text>
                    </div>
                    <div className={classes.items}>
                        <AddressIcon color="black" width={30} height={30} />
                        <Text style={{ marginLeft: 8, textAlign: 'center' }}>
                            {t.get('rgpd-modal.body.paragraph12.1')}
                        </Text>
                        <Text style={{ marginLeft: 8, textAlign: 'center' }}>
                            {t.get('rgpd-modal.body.paragraph12.2')}
                        </Text>
                    </div>
                </div>
                <Text>{t.get('rgpd-modal.body.paragraph10')}</Text>
            </div>
            <div className={classes.footer}>
                <InfosIcon color="black" width={50} height={50} />
                <div style={{ marginLeft: 8, width: '80%' }}>
                    <Text>{t.get('rgpd-modal.body.paragraph13.1')}</Text>
                    <Text>
                        <a
                            href={t.get('rgpd-modal.body.paragraph13.link')}
                            style={{ color: '#2BAAAA', textDecoration: 'none' }}
                        >
                            {t.get('rgpd-modal.body.paragraph13.2')}
                        </a>
                    </Text>
                    <Text>{t.get('rgpd-modal.body.paragraph14.1')}</Text>
                    <Text>
                        <a
                            href={t.get('rgpd-modal.body.paragraph14.link')}
                            style={{ color: '#2BAAAA', textDecoration: 'none' }}
                        >
                            {t.get('rgpd-modal.body.paragraph14.2')}
                        </a>
                    </Text>
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    container: {
        margin: 'auto',
        padding: '16px',
        maxWidth: '100%',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px',
        '@media (max-width: 600px)': {
            flexDirection: 'column',
        },
        '@media (min-width: 601px)': {
            flexDirection: 'row',
        },
    },
    infoBlock: {
        backgroundColor: '#2BAAAA',
        padding: '16px',
        borderRadius: '4px',
    },
    infoItem: {
        display: 'flex',
        alignItems: 'center',
        margin: '8px',
        flexDirection: 'column',
    },
    textGroup: {
        marginLeft: '8px',
        color: '#fff',
        maxWidth: '100%',
    },
    accessSection: {
        padding: '16px',
    },
    accessItems: {
        display: 'flex',
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
        '@media (min-width: 601px)': {
            flexDirection: 'row',
            justifyContent: 'space-around',
        },
    },
    items: {
        display: 'flex',
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
        '@media (min-width: 601px)': {
            flexDirection: 'row',
        },
    },
    contactSection: {
        backgroundColor: '#ccc',
        padding: '16px',
        borderRadius: '4px',
    },
    contactItems: {
        marginTop: 8,
        marginBottom: 8,
        display: 'flex',
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
        '@media (min-width: 601px)': {
            flexDirection: 'row',
            justifyContent: 'space-around',
        },
    },
    footer: {
        margin: '16px',
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
        '@media (min-width: 601px)': {
            flexDirection: 'row',
            justifyContent: 'space-around',
        },
    },
});

export default ModalRGPD;
