import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { makeStyles } from '@mui/styles';
import logo_navbar_light from 'assets/svgs/logo_navbar_light.svg';
import logo_navbar_dark from 'assets/svgs/logo_navbar_dark.svg';
import { CustomThemeContext } from '../../../theme/CustomThemeContext';
import { ENV } from '../../../theme/config';

export default function Menu(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const { theme } = useContext(CustomThemeContext);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleItemClick = (e, { onClick }) => {
        onClick();
        handleClose(e);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.container}>
            <Button
                color="primary"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <img
                    src={
                        theme === `${ENV}_dark`
                            ? logo_navbar_dark
                            : logo_navbar_light
                    }
                    alt="logo"
                    height={36}
                    width="auto"
                />
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                    className={classes.menuList}
                                >
                                    {props.items.map(item => (
                                        <MenuItem
                                            key={item.label}
                                            onClick={e =>
                                                handleItemClick(e, item)
                                            }
                                        >
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        zIndex: 101,
    },
    menuList: {
        backgroundColor: theme.palette.background.default,
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));
